import { useCookie } from "nuxt/app";
import { randomString } from "@/utils/string";

const COOKIE_OPTIONS = {
	maxAge: 86400 * 30,
	sameSite: true,
	secure: true,
};

export interface IdentityParams {
	state: string;
	token: string;
	redirect_url?: string;
	result: string;
}

export interface PlatformInfo {
	id: string;
	name: string;
}

export const useAuth = () => {
	const { platformName, authUrl } = useRuntimeConfig().public;
	const userStore = useUserStore();
	const { userLogin } = userStore;

	const getSignInURL = (
		redirect_url?: string,
		platformInfo?: PlatformInfo,
		tab: string = "sign_in",
		accountType?: string
	): string => {
		const csrf_token = randomString();
		const stateTokenCookie = useCookie<string | null>("state_token", {
			...COOKIE_OPTIONS,
			sameSite: "strict",
		});
		stateTokenCookie.value = csrf_token;
		const url = useRequestURL();
		const signin_params: any = {
			state: csrf_token,
			platform: platformName,
			return_url: url.origin + "/auth",
			name: "Tengiva",
		};
		if (redirect_url) {
			signin_params.redirect_url = redirect_url;
		}
		if (platformInfo) {
			signin_params.platformId = platformInfo.id;
			signin_params.name = platformInfo.name;
		}
		if (tab === "sign_up") {
			signin_params.tab = tab;

			if (accountType) {
				signin_params.accountType = accountType;
			}
		}

		const query_string = new URLSearchParams(signin_params).toString();
		return authUrl + "/sign-in?" + query_string;
	};

	const getRedirectURI = (query_params: any): string => {
		if (query_params.redirect_url) {
			return query_params.redirect_url.toString();
		}

		return "/";
	};

	const tengivaSSOSignIn = async (params: IdentityParams, platform_id: string) => {
		// Ensure the state returned matches the one we generated
		if (!params.token) {
			throw new Error("Missing Token");
		}

		// Confirm the state token wasn't touched
		const stateTokenCookie = useCookie<string | null>("state_token", {
			...COOKIE_OPTIONS,
			sameSite: "strict",
		});
		if (params.state !== stateTokenCookie.value) {
			throw new Error("Unrecognized state");
		}
		stateTokenCookie.value = null;

		await userLogin(
			{
				sso_partner: "Tengiva",
				sso_credential: params.token,
				platform_id,
			},
			true
		);
	};

	return {
		getRedirectURI,
		getSignInURL,
		tengivaSSOSignIn,
	};
};
