import flagsmith from "flagsmith";

export default defineNuxtPlugin(nuxtApp => {
	const { flagsmithEnvId } = useRuntimeConfig().public;
	if (!flagsmithEnvId || !flagsmithEnvId.length) return;

	nuxtApp.hook("app:beforeMount", async () => {
		await flagsmith.init({
			environmentID: flagsmithEnvId,
			cacheFlags: false, // stores flags in localStorage cache
			enableAnalytics: true, // See https://docs.flagsmith.com/flag-analytics/ for more info.
			// onChange: (oldFlags, params) => {
			// 	// Do something
			// },
		});
		nuxtApp.provide("featureFlags", flagsmith);
	});
});
