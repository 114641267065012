export default defineNuxtPlugin(app => {
	app.hook("app:created", async () => {
		const platformStore = usePlatformStore();
		const url = useRequestURL();
		const { mode } = useRuntimeConfig().public;
		let platformUrl = url.hostname.replaceAll(".", "-");
		if (mode !== "production") {
			if (url.hostname === "localhost") {
				platformUrl = "responsiblematerialsourcing-com";
			} else if (mode === "staging") {
				switch (url.hostname) {
					case "worldcollective.mktplc.xyz":
						platformUrl = "marketplace-world-collective-com";
						break;
					case "ecuadortextile.mktplc.xyz":
						platformUrl = "ecuadortextile-com";
						break;
					case "circulartextile.org":
					case "mktplc.xyz":
					default:
						platformUrl = "texsourcingagg-com";
				}
			}
		}

		await platformStore.getPlatform(platformUrl);
	});
});
