// create user related store
import { defineStore } from "pinia";
import type { Response } from "@tengiva/services-api-layer/types/response";

import type { LoginForm } from "@/composables/useURM";
import type { Organization } from "@/types/organizations";
import type { User } from "@/types/user";

// create user store
export const useUserStore = defineStore(
	"User",
	() => {
		const { login, logout } = useURM();
		const { viewOrganization } = useOrgMApi();
		const { userAuth } = useURMApi();
		const { $sentry } = useNuxtApp();

		// data
		const user = ref<User | null>();
		const userOrganization = ref<Organization | null>();

		const isLoggedIn = computed(() => !!user.value);
		// async|logic
		// function for user login
		async function userLogin(data: LoginForm, sso = false) {
			if (sso === false) {
				return Promise.reject(new Error("Cannot login without SSO from this platform."));
			}
			try {
				const userInfo = await login(data, true);
				user.value = userInfo;
				if (user.value.organization_id) {
					await setUserOrganization(user.value.organization_id);
				}
				return "ok";
			} catch (error: any) {
				if (error.data) {
					return Promise.reject(new Error(error.data.error.message));
				}
				return Promise.reject(error);
			}
		}

		// function for user logout
		async function userLogout() {
			await logout();
			sessionStorage.clear();
			user.value = null;
			userOrganization.value = null;
		}

		// fetch user info when there is token in cookie
		// user doesn't need to login again
		async function getUserInfo() {
			const { response } = await userAuth();
			user.value = (response?.data as any).user;

			if (user.value?.organization_id) {
				await setUserOrganization(user.value.organization_id);
			}

			if (user.value) {
				return "Success";
			} else {
				Promise.reject(new Error("Failed to retrieve user info."));
			}
		}

		async function setUserOrganization(organization_id: string) {
			if (organization_id) {
				try {
					const { response, error } = (await viewOrganization(organization_id)) as Response<Organization>;

					if (error) throw error;

					if (response?.data) {
						userOrganization.value = response.data;
					}
				} catch (error) {
					if (user.value?.id) {
						$sentry.captureException(
							`Cannot fetch organization ${organization_id} for current user ${user.value.id}, ${error}`
						);
					} else {
						$sentry.captureException(`Cannot fetch organization ${organization_id} for guest user, ${error}`);
					}
				}
			}
		}

		return {
			user,
			isLoggedIn,
			userOrganization,
			userLogin,
			userLogout,
			getUserInfo,
			setUserOrganization,
		};
	},
	{
		persist: {
			storage: persistedState.sessionStorage,
		},
	}
);
