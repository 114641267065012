import { createGtm } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin(nuxtApp => {
	const { gtm } = useRuntimeConfig().public;
	if (!gtm.id) return;
	nuxtApp.vueApp.use(
		createGtm({
			id: gtm.id,
			defer: true,
			enabled: true,
			debug: !import.meta.env.PROD,
			queryParams: {
				gtm_auth: gtm.gtm_auth,
				gtm_preview: "",
				gtm_cookies_win: "x",
			},
			vueRouter: useRouter(),
			loadScript: true,
			trackOnNextTick: true,
		})
	);
});
