import VueGtag, { trackRouter } from "vue-gtag-next";

export default defineNuxtPlugin(nuxtApp => {
	const { gtagId } = useRuntimeConfig().public;

	const url = useRequestURL();
	const platformStore = usePlatformStore();
	const { user, userOrganization } = useUserStore();

	const settings = platformStore.platform?.platform_settings[0];

	if (!gtagId) {
		return;
	}

	nuxtApp.vueApp.use(VueGtag, {
		property: {
			id: gtagId,
			params: {
				// platform data
				platform_id: settings?.id,
				tengiva_platform_code: settings?.tengiva_platform_code,
				service_subscription_id: settings?.service_subscription_id,
				platform_variant: settings?.platform_variant,
				platform_template_id: settings?.platform_template_id,
				platform_name: settings?.platform_config.name,
				platform_url: url.hostname,

				// user data
				user_id: user?.id,
				user_language: user?.language,
				tengiva_user_code: user?.tengiva_user_code,
				user_organization_id: user?.organization_id,
				user_title: user?.title,
				tengiva_organization_code: userOrganization?.tengiva_organization_code,
			},
		},
	});

	// TODO: consent

	trackRouter(useRouter());
});
