import * as Sentry from "@sentry/vue";

const IGNORED_ERRORS = [
	// error caused by 3rd parties, it won't break the website but throw error
	/ResizeObserver loop limit exceeded/,
	/'set' on proxy/,
	/proxy set handler returned false for property/,
];

export default defineNuxtPlugin(({ vueApp }) => {
	const { sentryDsn, mode, sentryReleaseVersion } = useRuntimeConfig().public;
	const router = useRouter();
	if (!import.meta.env.DEV) {
		Sentry.init({
			app: [vueApp],
			dsn: sentryDsn,
			integrations: [Sentry.browserTracingIntegration({ router })],
			tracesSampleRate: 1.0,
			release: sentryReleaseVersion,
			trackComponents: true,
			environment: mode,
			ignoreErrors: IGNORED_ERRORS,
		});
	}

	return {
		provide: {
			sentry: Sentry,
		},
	};
});
