<template>
	<NuxtLayout>
		<NuxtLoadingIndicator />
		<NuxtPage />
	</NuxtLayout>
</template>

<script lang="ts" setup>
const { recaptchaSiteKey } = useRuntimeConfig().public;
const { platformInitialized, plfm, platform, faviconUrl } = storeToRefs(usePlatformStore());

useHead({
	script: [
		{
			src: `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaSiteKey}`,
		},
	],
	meta: [
		{
			hid: "google-site-verification",
			name: "google-site-verification",
			content: platform.value?.platform_settings?.[0]?.platform_config.google_site_verification,
		},
	],
});

function applyFonts() {
	const bodyEl = document.querySelector("body");
	if (bodyEl) bodyEl.style.fontFamily = plfm.value.mainFont;

	const style = document.createElement("style");
	style.innerHTML = `.tg-title-font { font-family: ${plfm.value.secondaryFont} !important; }`;
	document.getElementsByTagName("head")[0].appendChild(style);
}

function applyColors() {
	const bodyEl = document.querySelector("body");
	if (bodyEl) bodyEl.style.color = plfm.value.colors.text;

	const titles = `.tg-title-color { color: ${plfm.value.colors.titles} !important; }`;
	const subtitles = `.tg-subtitle-color { color: ${plfm.value.colors.subtitles} !important; }`;
	const links = `.tg-link-color { color: ${plfm.value.colors.links} !important; }`;
	const style = document.createElement("style");
	style.innerHTML = `${titles} ${subtitles} ${links}`;
	document.getElementsByTagName("head")[0].appendChild(style);
}

function applyBtnStyles() {
	const style = document.createElement("style");
	style.innerHTML = `.tg-btn { font-family: ${plfm.value.mainFont} !important; color: ${plfm.value.btns.text} !important; border-radius: ${plfm.value.btns.rounding}px !important; background-color: ${plfm.value.btns.bg} !important; }`;
	document.getElementsByTagName("head")[0].appendChild(style);
}

onMounted(() => {
	let link = document.querySelector("link[rel~='icon']");
	if (!link) {
		link = document.createElement("link");
		link.rel = "icon";
		document.head.appendChild(link);
	}
	link.href = faviconUrl.value;

	applyFonts();
	applyColors();
	applyBtnStyles();

	platformInitialized.value = true;
});
</script>

<style>
html,
body {
	font-family: "Lato";
}
</style>
