export const parseJWT = (token: string) => {
	if (typeof window !== "undefined") {
		const base64Url = token.split(".")[1];
		const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		const jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);
		return JSON.parse(jsonPayload);
	} else {
		const jwtArray = token.split(".");
		const jwtPayloadEncoded = jwtArray[1];
		const bufferJwtPayload = Buffer.from(jwtPayloadEncoded, "base64");
		const jwtPayloadDecodedString = bufferJwtPayload.toString();
		const jwtPayloadDecodedObject = JSON.parse(jwtPayloadDecodedString);
		return jwtPayloadDecodedObject;
	}
};
