export function formatNumberWithCommas(num: number) {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatStringForUrl(str: string) {
	return str.toLowerCase().replaceAll(" ", "-");
}
export const capitalizeStrings = (str: any) => {
	const newString = String(str);
	return newString
		.split("-")
		.map(part => {
			return part.charAt(0).toUpperCase() + part.slice(1);
		})
		.join(" ");
};

function hexToRgb(hex: string) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
}

function luminance(hex: string) {
	const RED = 0.2126;
	const GREEN = 0.7152;
	const BLUE = 0.0722;
	const GAMMA = 2.4;

	const rgb = hexToRgb(hex);
	if (!rgb) return null;
	const a = [rgb.r, rgb.g, rgb.b].map(v => {
		v /= 255;
		return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA);
	});
	return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

export function contrast(hex1: string, hex2: string) {
	const lum1 = luminance(hex1);
	const lum2 = luminance(hex2);
	if (typeof lum1 !== "number" || typeof lum2 !== "number") return null;
	const brightest = Math.max(lum1, lum2);
	const darkest = Math.min(lum1, lum2);
	return (brightest + 0.05) / (darkest + 0.05);
}

export function pickTextColor(hex: string) {
	const black = "#000000";
	const white = "#FFFFFF";
	const blackContrast = contrast(hex, "#000000");
	const whiteContrast = contrast(hex, "#FFFFFF");
	if (typeof blackContrast !== "number" || typeof whiteContrast !== "number") return black;
	return blackContrast > whiteContrast ? black : white;
}

export function deduceBtnRounding(val?: string) {
	if (val === "square") return 0;
	else if (val === "round") return 10;
	return 4;
}
