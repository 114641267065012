import { createI18n } from "vue-i18n";
import en from "@/lang/en.json";
import fr from "@/lang/fr.json";

type i18nMessage = {
	en: { [key: string]: any };
	fr: { [key: string]: any };
};

const messages: i18nMessage = {
	en,
	fr,
};

export default defineNuxtPlugin(({ vueApp }) => {
	const i18n = createI18n({
		legacy: false,
		allowComposition: true,
		globalInjection: true,
		fallbackLocale: "en",
		locale: "en",
		numberFormats: {
			en: {
				currency: {
					style: "currency",
				},
			},
		},
		messages,
	});

	vueApp.use(i18n);
});
